body
{
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body
{
    margin: 0;
    padding: 0;

    /* Safari fix */
    overflow-x: hidden;
}

h2
{
    margin-top: 32px;
    margin-bottom: 16px;

    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

h3
{
    margin-top: 32px;
    margin-bottom: 16px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
}

/* Bootstrap 5 tweak: do not underline links unless hovered over */
a
{
    color: #4462ae;
    text-decoration: initial;
}

a:hover
{
    cursor: pointer;
    color: #4462ae;

    transition: all .25s ease-out;
}

:target
{
    scroll-margin-top: 72px;
}

.page
{
    min-height: 100vh;
}

.debug-responsive-container
{
    position: fixed;
    display: inline-block;

    bottom: 0px;
    left: 0px;

    z-index: 9999;
}

.debug-responsive-block
{
    display: inline-block;

    width: 16px;
    height: 16px;

    background-color: blue;
}

.debug-responsive-none
{
    display: inline-block;

    width: 16px;
    height: 16px;
    
    border-style: solid;
    border-width: 1px;
    border-color: blue;
}

.debug-responsive-text
{
    position: absolute;

    top: -3px;
    margin-left: 4px;

    color: blue;
}

.debug-responsive-text-link
{
    position: absolute;

    top: -3px;
    margin-left: 35px;

    color: blue;
}

.debug-responsive-text-link:hover
{
    cursor: pointer;

    text-decoration: underline;
}