.masonry
{
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    
    margin-top: -48px;
    width: auto;
}

/* XS to MD */
@media (max-width: 991.98px)
{ 
    .masonry
    {
        margin-left: -24px; /* gutter size offset */
    }
}
/* LG */
@media (min-width: 992px) and (max-width: 1199.98px)
{
    .masonry
    {
        margin-left: -24px; /* gutter size offset */
    }
}
/* XL */
@media (min-width: 1200px)
{
    .masonry
    {
        margin-left: -24px; /* gutter size offset */
    }
}

.masonry-column
{
    background-clip: padding-box;
}

/* XS to MD */
@media (max-width: 991.98px)
{ 
    .masonry-column
    {
        padding-left: 24px; /* gutter size */
    }
}
/* LG */
@media (min-width: 992px) and (max-width: 1199.98px)
{
    .masonry-column
    {
        padding-left: 24px; /* gutter size */
    }
}
/* XL */
@media (min-width: 1200px)
{
    .masonry-column
    {
        padding-left: 24px; /* gutter size */
    }
}