.loading
{
    margin-top: 24px;

    width: 48px;
    height: 48px;

    background-image: url('/public/image/icon_loading.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 48px 48px;

    animation-name: loading-spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}

@keyframes loading-spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}