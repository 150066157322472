.footer-section
{
    padding-top: 18px;
    padding-bottom: 48px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;

    background-color: #252525;
    background-position: center top;
    background-size: cover;
}

.footer-line
{
    margin-top: 12px;
    margin-bottom: 12px;

    background-color: #939393;
    color: #939393;
}

.footer-container-logo
{
    margin-top: 32px;
}

.footer-container-paragraph
{
    display: inline-block;
    vertical-align: top;

    margin-top: 32px;
    margin-right: 48px;
    max-width: 240px;
}

.footer-container-links
{
    margin-top: 32px;
}

.footer-logo
{
    width: 124px;
    height: 46px;
    
    background-image: url('/public/image/logo_financeteams_gray.svg');
    background-repeat: no-repeat;
    background-size: 124px 46px;
}

.footer-title
{
    margin-bottom: 12px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 600;

    color: #939393;
}

.footer-text
{
    font-size: 16px;
    line-height: 22px;

    color: #939393;
}

.footer-link
{
    display: inline-block;

    margin-right: 24px;
    
    color: #FFFFFF;
}

.footer-link:hover
{
    color: #FFFFFF;

    cursor: pointer;

    text-decoration: underline;
}

.footer-link-alt
{
    display: inline-block;
    
    color: #298ce8;
}

.footer-link-alt:hover
{
    cursor: pointer;

    color: #0056b3;
    text-decoration: underline;
}

.footer-button-linkedin
{
    display: inline-block;

    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 32px;
    padding-right: 32px;

    background-color: #0b66c2;
    color: #ffffff;

    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.125);

    border-color: #0b66c2;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
}

.footer-button-linkedin:hover
{
    background-color: #1972cb;
    color: #ffffff;

    text-decoration: none;

    border-color: #0b66c2;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    cursor: pointer;
}