.banner-section
{
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    
    padding-top: 64px;
    padding-bottom: 64px;

    background-position: center top;
    background-size: cover;
}

.banner-subsection
{
    margin-top: 48px;
}

.banner-title-mobile
{
    margin: 0;
    margin-top: 0px;
    margin-bottom: 8px;

    font-size: 36px;
    line-height: 42px;
    font-weight: 600;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    
    color: #000000;
}

.banner-title-tablet
{
    margin: 0;
    margin-top: 0px;

    font-size: 38px;
    line-height: 46px;
    font-weight: 600;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    
    color: #000000;
}

.banner-title-desktop
{
    margin: 0;
    margin-top: 0px;
    margin-bottom: 12px;

    font-size: 54px;
    line-height: 60px;
    font-weight: 600;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    
    color: #000000;
}

.banner-subtitle-mobile
{
    margin-top: 0px;
    margin-bottom: 24px;

    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;
    -webkit-font-smoothing: antialiased;

    color: #212529;
}

.banner-subtitle-desktop
{
    margin-top: 0px;

    font-size: 28px;
    line-height: 34px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #212529;
}

.banner-button
{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    
    padding-left: 30px;
    padding-right: 30px;
    height: 60px;
    
    color: #ffffff;
    background-color: #4462ae;

    font-size: 18px;
    font-weight: 400;
    text-decoration: none;

    border-color: #4462ae;
    border-style: solid;
    border-width: 2px;
    border-radius: 30px;

    transition: all .25s ease-out;
}

.banner-button:hover
{
    color: #4462ae;
    background-color: #ffffff;

    text-decoration: none;
    
    cursor: pointer;
}

.banner-button-alt
{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    
    margin-left: 30px;
    padding-left: 0px;
    padding-right: 0px;
    height: 60px;
    
    color: #000000;

    font-size: 18px;
    font-weight: 400;
    text-decoration: none;

    border-color: #ffffff;
    border-style: solid;
    border-width: 2px;

    transition: all .25s ease-out;
}

.banner-button-alt:hover
{
    color: #4462ae;

    text-decoration: none;

    cursor: pointer;
}

.banner-button-alt-chevron
{
    display: inline-block;

    margin-left: 12px;
    width: 20px;
    height: 20px;

    background-image: url('/public/image/icon_chevronright.svg');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: center;

    transition: margin-left .25s ease-out;
}

.banner-button-alt:hover .banner-button-alt-chevron
{
    margin-left: 20px;

    background-image: url('/public/image/icon_chevronright_blue.svg');
}

.banner-container
{
    align-items: center;
    display: flex;

    padding-top: 0px;
    min-height: 450px;
}

.banner-container-align
{
    width: 100%;
}

.banner-profiles-container
{
    align-items: center;
    display: flex;

    padding-top: 0px;
    min-height: 450px;
}

.banner-profiles-subcontainer-desktop
{
    width: 100%;
}

.banner-profiles-subcontainer-tablet
{
    width: 100%;
}

.banner-profiles-subcontainer-mobile
{
    width: 100%;
    text-align: center;
}

.banner-profiles-subcontainer-top-mobile
{
    margin-top: 24px;
    width: 100%;
}

.banner-profiles-subcontainer-top-desktop
{
    margin-top: 16px;
    width: 100%;
}

.banner-profiles-subcontainer-bottom-mobile
{
    margin-top: -14px;
    margin-bottom: 6px;
    width: 100%;
}

.banner-profiles-subcontainer-bottom-desktop
{
    margin-top: -19px;
    width: 100%;
}

@media (min-width: 1400px)
{
    .banner-profiles-subcontainer-bottom-desktop
    {
        margin-top: -22px;
    }
}

.banner-profiles-subcontainer-bottom-tablet
{
    margin-top: -17px;
    margin-bottom: 6px;
    width: 100%;
}

@media (min-width: 992px)
{
    .banner-profiles-subcontainer-bottom-tablet
    {
        margin-top: -23px;
    }
}

.banner-profiles-circle-desktop
{
    display: inline-block;

    width: calc(28.57% - 8px);
    margin-right: 8px;

    aspect-ratio: 1 / 1;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;

    border-style: solid;
    border-width: 2px;
    border-color: #ffffff;
    border-radius: 100%;
}

.banner-profiles-circle-desktop-offset
{
    display: inline-block;
    
    width: 14.2%;
    aspect-ratio: 1 / 1;
}

.banner-profiles-circle-mobile
{
    display: inline-block;

    width: calc(28.57% - 8px);
    margin-right: 8px;

    aspect-ratio: 1 / 1;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;

    border-style: solid;
    border-width: 2px;
    border-color: #ffffff;
    border-radius: 100%;
}

.banner-profiles-circle-mobile-offset
{
    display: inline-block;
    
    width: 14.2%;
    aspect-ratio: 1 / 1;
}

.banner-profiles-circle-table
{
    display: inline-block;

    width: calc(40% - 8px);
    margin-right: 8px;

    aspect-ratio: 1 / 1;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;

    border-style: solid;
    border-width: 2px;
    border-color: #ffffff;
    border-radius: 100%;
}

.banner-profiles-circle-table-offset
{
    display: inline-block;
    
    width: 20%;
    aspect-ratio: 1 / 1;
}